!function i(s,u,a){function c(t,e){if(!u[t]){if(!s[t]){var n="function"==typeof require&&require;if(!e&&n)return n(t,!0);if(f)return f(t,!0);var r=new Error("Cannot find module '"+t+"'");throw r.code="MODULE_NOT_FOUND",r}var o=u[t]={exports:{}};s[t][0].call(o.exports,function(e){return c(s[t][1][e]||e)},o,o.exports,i,s,u,a)}return u[t].exports}for(var f="function"==typeof require&&require,e=0;e<a.length;e++)c(a[e]);return c}({1:[function(e,t,n){
/**
 * Bootstrap Carousel Swipe v1.1
 *
 * jQuery plugin to enable swipe gestures on Bootstrap 3 carousels.
 * Examples and documentation: https://github.com/briggySmalls/bcSwipe
 *
 * @license MIT
 */
var u,a=e("detect-passive-events");(u=jQuery).fn.bcSwipe=function(e){var s={threshold:50};return e&&u.extend(s,e),this.each(function(){var r,o=!1;function i(e){if(o){var t=e.touches[0].pageX,n=r-t;Math.abs(n)>=s.threshold&&(function(){this.removeEventListener("touchmove",i),r=null,o=!1}.call(this),0<n?u(this).carousel("next"):u(this).carousel("prev"))}}"ontouchstart"in document.documentElement&&this.addEventListener("touchstart",function(e){1==e.touches.length&&(r=e.touches[0].pageX,o=!0,this.addEventListener("touchmove",i,!!a.hasSupport&&{passive:!0}))},!!a.hasSupport&&{passive:!0})}),this}},{"detect-passive-events":2}],2:[function(e,t,n){"use strict";Object.defineProperty(n,"__esModule",{value:!0});var r={update:function(){if("undefined"!=typeof window&&"function"==typeof window.addEventListener){var e=!1,t=Object.defineProperty({},"passive",{get:function(){e=!0}}),n=function(){};window.addEventListener("testPassiveEventSupport",n,t),window.removeEventListener("testPassiveEventSupport",n,t),r.hasSupport=e}}};r.update(),n.default=r},{}]},{},[1]);
